import {CONTENT_WIDTH} from '../../utils/constants';
import {getContainerStyle} from '../../utils/variable-extractor';
import VariableValueExtractor from "../utils/VariableValueExtractor";

const RuleTextColorBox = ({
    variables,
    template,
    cards,
    card,
    deckId,
    fullWidth,
    templateData,
    ctaId,
    parentId
}) =>{

    // XXX: "73a1b176-a04b-4188-8a2f-31be7bcfed34"
    
    if(variables.variablesValues){
        variables = variables.variablesValues;
    }
    
    var topContainer = template.render[0]?.children[0]?.uuid;

    const containerStyle = {
        ...getContainerStyle(topContainer, template, cards, card, variables, templateData, null),
        width: !fullWidth ? CONTENT_WIDTH : null,
        padding:"7px"
    }

    var textId = template.render[0]?.children[0]?.children[0]?.variable;
    var renderId = template.render[0]?.children[0]?.children[0]?.uuid

    return (
        <div name={`${template._id} - ${template.name}`} className="mt-3" style={containerStyle}>
            <VariableValueExtractor variableId={textId}
                template={template}
                cards={cards}
                card={card}
                variables={variables}
                templateData={templateData}
                renderId={renderId}
                isLink 
                ctaId={ctaId}
                parentId={parentId}/>
        </div>
    )
}

export default RuleTextColorBox;