import './_templates.scss'
import { findVariableOrDefaultToTempateValue } from '../../utils/variable-extractor';
import { BLOB_URL } from '../../utils/constants';

const FullImagePage = ({
    currentCard,
    template,
    cards,
    deckId, 
    templateData,
 
}) => {

    const backgroundImage = findVariableOrDefaultToTempateValue("d6afd089-baab-4964-af31-d475b4d1fa48",  template, cards, currentCard, currentCard.variablesValues, templateData).value;

    return (
        <div className="w-full h-full background-image" style={{backgroundImage:`url(${BLOB_URL}${backgroundImage})`}}>
        </div>
    )
}

export default FullImagePage;