import { CONTENT_WIDTH } from '../../utils/constants';
import VariableValueExtractor from "../utils/VariableValueExtractor";

const TextBookletGeneral = ({
    variables,
    template,
    cards,
    card,
    deckId,
    fullWidth,
    templateData,
    ctaId,
    parentId
}) => {

    // XXX: "73a1b176-a04b-4188-8a2f-31be7bcfed34"

    if(variables && variables.variablesValues){
        variables = variables.variablesValues;
    }

    let textRenderId = template.render[0].children[0].uuid;
    let variableId = template.render[0].children[0].variable;
    
    if(!variableId) variableId = textRenderId;

    return (
        <div name={`${template._id} - ${template.name}`} className="mt-2 textCustom" style={{width: !fullWidth ? CONTENT_WIDTH : null, lineHeight: "18px"}}>
            <VariableValueExtractor variableId={variableId}
                template={template}
                cards={cards}
                card={card}
                variables={variables}
                templateData={templateData}
                renderId={textRenderId}
                isLink 
                ctaId={ctaId}
                parentId={parentId}/>
        </div>
    )
}

export default TextBookletGeneral;