import { CONTENT_WIDTH } from '../../utils/constants';
import { getContainerStyle, findNestedModuleVariables, findNestedVariable, getRenderColor } from '../../utils/variable-extractor';
import ModuleSelector from './_module-selector';
import ClanIcons from '../icons/ClanIcons';
import VariableValueExtractor from "../utils/VariableValueExtractor";


const TemplateStorybookConclusionSideQuestAvailable = ({
    variables,
    template,
    cards,
    card,
    deckId,
    fullWidth,
    templateData,
    ctaId,
    parentId
}) => {

    if (variables && variables.variablesValues) {
        variables = variables.variablesValues;
    }

    let containerRender = template.render[0];

    const masterContainerStyle = {
        ...getContainerStyle(containerRender?.uuid, template, cards, card, variables, templateData, null),
        paddingTop: "4px",
        paddingBottom: "4px",
        paddingLeft: "7px",
        paddingRight: "7px"
    }

    const ctaVariableId = template.render[0]?.children[1]?.variable;
    const textVariable = template.render[0]?.children[1]?.uuid;

    return (
        <div name={`${template._id} - ${template.name}`} className="mt-3 flex " style={{ ...masterContainerStyle, width: !fullWidth ? CONTENT_WIDTH : null }}>
            <div className='grow'>
                <VariableValueExtractor variableId={textVariable}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    isLink 
                    ctaId={ctaId}
                    parentId={parentId}/>
                <div>
                    {findNestedModuleVariables(ctaVariableId, variables)?.map((variable, index) => {
                        return (
                            <ModuleSelector
                                key={index}
                                variable={variable}
                                card={card}
                                cards={cards}
                                deckId={deckId}
                                templateData={templateData}
                                separatorColor={'black'}
                            />
                        )
                    })}
                </div>
                
            </div>
            <div className='flex items-center'>
                <ClanIcons name={findNestedVariable("0fb30447-3468-48c1-aaaf-3c60050b97df", variables)?.value} 
                color={getRenderColor("d1345c4b-f5a2-47c8-bf1e-085413cd7ec0", template.render, templateData)}
                />
            </div>
           
        </div>
    )
}

export default TemplateStorybookConclusionSideQuestAvailable;