import './_templates.scss'
import { findModuleVariables, findNestedModuleVariables, getContainerStyle } from '../../utils/variable-extractor';
import { BLOB_URL, DEFAULT_ORANGE_CODE, V_NUMBER } from '../../utils/constants';
import ModuleSelector from '../modules/_module-selector';
import VariableValueExtractor from '../utils/VariableValueExtractor';

const SectionEvent = ({
    currentCard,
    template,
    cards,
    deckId,
    templateData,
    ctaId,
}) => {

    // EVENT : "c85ab9c0-8210-4260-bf6d-98981a8c3c7c"
    // EVENT Page Number : "bce700ec-ee20-4354-8521-78b112b4dcd2"

    const parentId = currentCard._id;

    const masterContainerStyle = {
        ...getContainerStyle("fc89dde6-2518-4488-98cc-1197ef1d2f21", template, cards, currentCard, currentCard.variablesValues, templateData, null)
    }


    return (
        <div className="w-full h-full overflow-y-auto pb-5" style={masterContainerStyle}>
            <div className='top-section flex flex-col'>
                <div className='flex'>
                    <div className='right w-full flex items-center justify-center mt-10'>
                        <div className="flex flex-col items-center w-full px-4 max-w-md">
                            <img src={BLOB_URL +"upload/images/64ed011e17b7622c4d323360/Booklets/icons/Ank+Line.webp" + "?v=" + V_NUMBER} style={{ maxWidth: "250px" }} alt="ank-line" />

                            <div className='flex text-center'>
                                <div className='flex-none'>
                                    <VariableValueExtractor variableId={"07e7be41-8400-4ab8-8ca8-f095211a8b31"}
                                        template={template}
                                        cards={cards}
                                        card={currentCard}
                                        variables={currentCard.variablesValues}
                                        templateData={templateData}
                                        renderId={null}
                                        isLink={null}
                                        ctaId={ctaId}
                                        parentId={parentId}/>
                                </div>
                                <div className='space' style={{ width: '5px' }}></div>
                                    <VariableValueExtractor variableId={"1532fc0f-1773-4d7e-b308-30d0490f9282"}
                                        template={template}
                                        cards={cards}
                                        card={currentCard}
                                        variables={currentCard.variablesValues}
                                        templateData={templateData}
                                        renderId={null}
                                        isLink 
                                        ctaId={ctaId}
                                        parentId={parentId}/>
                            </div>
                            <img src={BLOB_URL +"upload/images/64ed011e17b7622c4d323360/Booklets/icons/Line+Tips.webp" + "?v=" + V_NUMBER} style={{ maxWidth: "250px" }} alt="ank-line" />
                        </div>
                    </div>
                </div>
                <div className='flex items-center justify-center flex-col mt-2'>
                    {findModuleVariables("42dd4da9-a477-46b7-bade-6426250db73d", currentCard).map((variable, index) => {
                        return (
                            <ModuleSelector
                                key={index}
                                variable={variable}
                                card={currentCard}
                                cards={cards}
                                deckId={deckId}
                                templateData={templateData}
                            />
                        )
                    })}
                </div>
            </div>
            <div className='relative line-section w-full justify-end mt-10' style={{ backgroundColor: DEFAULT_ORANGE_CODE, height: '1.5px' }}>
                <img className='absolute w-2' style={{ left: 'calc(50% - 4px)', top: '-0.6rem' }} src={BLOB_URL +"upload/images/64ed011e17b7622c4d323360/Booklets/icons/Ank_Line_Gold.webp" + "?v=" + V_NUMBER} alt="ank" />
            </div>
            <div className='bottom-section'>
                <div className='flex flex-col items-center mt-2'>
                    {findNestedModuleVariables("0cf0533e-0a9f-419c-81fc-6d18c5777072", currentCard.variablesValues).map((variable, index) => {
                        return (
                            <ModuleSelector
                            key={index}
                            variable={variable}
                            card={currentCard}
                            cards={cards}
                            deckId={deckId}
                            templateData={templateData}
                            />
                            )
                        })}
                </div>
            </div>
        </div>
    )
}
export default SectionEvent;