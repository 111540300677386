import { getContainerStyle, findModuleVariables } from '../../utils/variable-extractor';
import ModuleSelector from './_module-selector';
import VariableValueExtractor from "../utils/VariableValueExtractor";
import { CONTENT_WIDTH } from '../../utils/constants';


const TemplateStorybookConclusionFromThisPointOnSandbox = ({
    variables,
    template,
    cards,
    card,
    deckId,
    fullWidth,
    templateData,
    ctaId,
    parentId
}) => {

    if (variables && variables.variablesValues) {
        variables = variables.variablesValues;
    }

    // let containerRender = template.render[0];
    // let textRender = containerRender.children[0].children[0];
    // let imageRender = containerRender.children[1].children[0];

    const masterContainerStyle = {
        ...getContainerStyle("d862d992-3611-4e5a-88f9-d398af8c7633", template, cards, card, variables, templateData, null),
        paddingTop:"4px",
        paddingBottom:"4px",
        paddingLeft:"7px",
        paddingRight:"7px"
    }

    return (
        <div name={`${template._id} - ${template.name}`} className="mt-3 flex flex-col text-center" style={{ ...masterContainerStyle,  width: !fullWidth ? CONTENT_WIDTH : null }}>
            <VariableValueExtractor variableId={"11862912-cd73-4a90-ac12-1652262e9137"}
                        template={template}
                        cards={cards}
                        card={card}
                        variables={variables}
                        templateData={templateData}
                        renderId={null}
                        isLink 
                        ctaId={ctaId}
                        parentId={parentId}/>
    
            <div style={{ marginTop: "0rem"}}>
                {findModuleVariables("1f8aeff0-069e-40d9-a7ed-1ccfb6b6a2eb", variables).map((variable, index) => {
                    return (
                        <ModuleSelector
                            key={index}
                            variable={variable}
                            card={card}
                            cards={cards}
                            deckId={deckId}
                            templateData={templateData}
                            fullWidth={true}
                        />
                    )
                })}
            </div>
        </div>
    )
}

export default TemplateStorybookConclusionFromThisPointOnSandbox;